.main-content {
  ol {
    list-style-type: decimal;

    >li {
      position: relative;

      &::before {
        content: none;
      }

      // Nested lists (if any)
      ol {
        counter-reset: sub-counter;

        >li {
          &::before {
            content: none;
          }
        }
      }
    }
  }
}

// Original
// .main-content {
//   ol {
//     list-style-type: none;
//     counter-reset: step-counter;

//     >li {
//       position: relative;

//       &::before {
//         position: absolute;
//         top: 0.2em;
//         left: -1.6em;
//         color: #0F0;
//         content: counter(step-counter);
//         counter-increment: step-counter;
//         @include fs-3;

//         @include mq(sm) {
//           top: 0.11em;
//         }
//       }

//       ol {
//         counter-reset: sub-counter;

//         >li {
//           &::before {
//             color: #0F0;
//             content: counter(sub-counter, lower-alpha);
//             counter-increment: sub-counter;
//           }
//         }
//       }
//     }
//   }
// }